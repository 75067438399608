import type { PageWithLayout } from '@/types/AppWithLayout';
import CaireHead from '@/components/CaireHead';
import { useAuth0 } from '@auth0/auth0-react';
import useProgramConfig from '@/hooks/useProgramConfig';
import { Center, Loader, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useWhoAmI from '@/hooks/useWhoAmI';

const loggedOutHomePath = '/join/start';
const loggedInHomePath = '/dashboard';

const Home: PageWithLayout = () => {
  const { programName } = useProgramConfig();
  const { isAuthenticated, isLoading } = useAuth0();
  const { user } = useWhoAmI();
  const { push } = useRouter();

  // Not logged in, redirect to welcome page
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      push(loggedOutHomePath);
    }
  }, [isAuthenticated, isLoading, push]);

  // Logged in, take to dashboard
  useEffect(() => {
    if (!user) {
      return;
    }

    if (user?.id) {
      push(loggedInHomePath);
    }
  }, [user, push]);

  return (
    <>
      <CaireHead title={programName} />
      {/* eslint-disable-next-line react/forbid-elements --
       * Allow <main> element as this standalone page does not use a layout */}
      <main>
        <Center style={{ height: 'calc(50vh)' }}>
          <Stack gap="12px" align="center">
            <Loader size={48} />
            <p>Loading…</p>
          </Stack>
        </Center>
      </main>
    </>
  );
};

export default Home;
