import type { FunctionComponent, PropsWithChildren } from 'react';
import Head from 'next/head';

interface CaireHeadProps extends PropsWithChildren {
  title?: string;
  description?: string;
}

const CaireHead: FunctionComponent<CaireHeadProps> = ({
  children,
  title,
  description,
}) => {
  const pageTitle = title
    ? `${title} — ${process.env.NEXT_PUBLIC_APP_TITLE}`
    : process.env.NEXT_PUBLIC_APP_TITLE;
  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      <meta name="description" content={description ?? ''} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.png" />
      {children}
    </Head>
  );
};

export default CaireHead;
