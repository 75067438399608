import useAuthenticatedQuery from '@/hooks/useAuthenticatedQuery';
import { useWhoAmIQuery } from '@/lib/graphql';
import { WatchQueryFetchPolicy } from '@apollo/client';

/**
 * Wraps the whoAmI query and recomposes the data into a `user` and `program` object
 * response, for easier consumption. Uses the Apollo cache.
 */
const useWhoAmI = ({
  fetchPolicy = 'cache-first',
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
} = {}) => {
  const { data, loading, error } = useAuthenticatedQuery(useWhoAmIQuery, {
    fetchPolicy: fetchPolicy,
  });

  const user = data?.whoAmI;

  return {
    user,
    isLoading: loading,
    error,
  };
};

export default useWhoAmI;
